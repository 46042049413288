/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #082a40 !important;
  color: #fff !important;
  font-family: Georgia, 'Times New Roman', Times, serif !important;
  text-align: justify !important;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
    -ms-content-zooming: none;
    touch-action: manipulation;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

#img {
  width: 250px;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  transition: transform 0.3s ease-in-out;
}
.navbar-link:hover {
  transform: scale(1.1);
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  text-decoration: none !important;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text a {
  text-decoration: none;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
@media (min-width: 300px) and (max-width: 746px) {
  nav.navbar {
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    overflow-x: hidden;
  }

  nav.navbar.scrolled {
    padding: 0;
    background-color: #000;
  }

  nav.navbar .navbar-toggler-icon {
    border-bottom: 2px solid #fff;
  }
}

@media (max-width: 746px) {
  .social-icon {
    display: none;
  }

  .navbar-text button {
    display: none;
  }
}

@media (min-width: 747px) {
  .social-icon {
    display: flex;
  }

  .navbar-text button {
    display: flex;
  }
}

/****************************/

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 200px 0 100px 0;

  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner a {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner a:hover svg {
  margin-left: 25px;
}
.banner-img2 {
  animation: updown 3s linear infinite;
  width: 20rem;
  height: auto;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

@media screen and (min-width: 300px) and (max-width: 746px) {
  .banner .tagline {
    margin-top: -35px;
  }
  .banner h1 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
  }
}

/**********contact ********************/
.contact {
  margin: 0;
  padding: 120px 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.contact img {
  width: 100%;
  max-width: 20rem;
  height: auto;
  margin-bottom: 30px;
}

.contact h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin: 0 0 15px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 16px;
  margin-top: 25px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  border-radius: 20px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}
.Contact_Head{
  font-size: 40px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .contact {
    margin-top: 100px;
    padding: 30px;
  }

  .contact img {
    width: 100%;
    max-width: none;
  }

  .contact h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .contact form input,
  .contact form textarea {
    font-size: 14px;
    padding: 15px 20px;
  }

  .contact form button {
    font-size: 14px;
    padding: 12px 36px;
  }
}

/*********************course-cards************************/

#services-h3 {
  text-align: center;
  font-weight: 700;
  font-size: 50px;
}

.cwl {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: -80px;

  animation: updown 3s linear infinite;
  transition: 0.3s ease-in-out;
}

.cwl-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px 50px;
}

.cwl-card .card {
  position: relative;
  width: 350px;
  height: 400px;

  display: flex;

  align-items: center;

  border-radius: 20px;

  transition: 0.5s;

  justify-content: flex-start;
}
.cwl-card :hover {
  transform: scale(1.04) rotate(1deg);
}

.cwl-card .card .imgBx {
  position: absolute;
  width: 20rem;
  height: 20rem;
  border-radius: 12px;
  overflow: hidden;
  top: -130px;
  transform: scale(0.75);
}

.cwl-card .card .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cwl-card .card .content h2 {
  position: absolute;
  top: 140px;
  width: 100%;
  padding: 0 35px;
  overflow: hidden;
  text-align: center;
}

.cwl-card #p1 {
  margin: 12rem 2rem;
  text-align: justify;
}

.cwl-card .btn {
  position: absolute;
  margin: 10px 25%;
  color: #fff;
  background-color: #0b666a;
  top: 20rem;
  width: 10rem;
  height: 3rem;
  border-radius: 20px;
}

.cwl-card a {
  text-decoration: none;
}
.cwl-card .btn:hover {
  background-color: #071952;
  color: #fff;
}

@media screen and (min-width: 1080px) and (min-height: 2412px) {
  
  .cwl-card {
    padding: 50px;
  }

  .cwl-card .card {
    width: 300px;
    height: 350px;
  }

  .cwl-card .card .imgBx {
    width: 15rem;

    height: 12rem;
  }

  .cwl-card .card .content h2 {
    top: 110px;
  }

  .btn {
    margin: 10px 20%;

    top: 18rem;

    width: 8rem;

    height: 2.5rem;
  }
}

@media screen and (min-width: 300px) and (max-width: 746px) {
  .cwl{
    margin-top:10px;
  }
  .cwl-card .card {
    top: 90px;
    left: 13px;
    margin-right: 1.5rem;
  }
  #services-h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  .cwl-card .card {
    width: calc(100% - 30px);
    max-width: none;
  }
}

/*********OurCourses******************/
.ourCourses-banner {
  position: relative;
  text-align: center;
  margin-bottom: 90px; 
  width: 100%; 
  padding: 0;
  margin: 0;
  overflow: hidden;
  
}

.OurCourses_Banner{
  position: relative;
  
}
.coursehead_img{
  margin-top: -50px;
  width: 100%;
  height:auto;

}
.centered {
  position:absolute;
  font-size:80px;
  font-weight: 900px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 35px;
  
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
  padding: 0 10px;
  margin: 60px 0;
  text-decoration:none;
}
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 354px;
  perspective: 1000px;
  color: #000;
  margin: 10px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 1rem;
}
.flip-card-back p{
  text-align: justify;
  margin: 0 20px;
}
.flip-card-back a{
  margin-top: 20px;
}
.flip-card-front {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
  background-color: #fff;
}

.flip-card-back {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
  background-color: #fff;
  transform: rotateY(180deg);
}

.download-button {
  background-color: #38b6ff;
  color: white;
  padding: 10px 20px;
  margin: 20px  30px;
  
  border: none;
  border-radius: 35px;
  cursor: pointer;
}
.download-button:hover {
  background-color: #004aad;
  color: white;
  padding: 10px 20px;
  margin: 20px  30px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
}
.OurCourse_title{
  font-size: 25px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#OurCourseimg{
  width: 200px;
  height: auto;
  top:50px;
  
}
@media screen and (max-width: 600px) {
  .flip-card {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  .coursehead_img{
    margin-top: 100px;
    width: 100%;
    height:200px;
  
  }
  .centered {
    position:absolute;
    text-align:center;
    font-size:25px;
    font-weight: 900;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 35px;
    
  }
  .card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
    padding: 0 10px;
    margin: 60px 0;
  }
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 354px;
    perspective: 1000px;
    color: #000;
    margin: 10px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 1rem;
  }
  
  .flip-card-front {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
    background-color: #fff;
  }
  
  .flip-card-back {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset;
    background-color: #fff;
    transform: rotateY(180deg);
  }
  
  .download-button {
    background-color: #38b6ff;
    color: white;
    padding: 10px 20px;
    margin: 0  30px;
    border: none;
    border-radius: 35px;
    cursor: pointer;
  }
  .download-button:hover {
    background-color: #004aad;
    color: white;
    padding: 10px 20px;
    margin: 0  30px;
    border: none;
    border-radius: 35px;
    cursor: pointer;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #OurCourseimg{
    width: 250px;
    height: 250px;
    top:50px;
    
  }
}

/**********************************/

/*****************School*****************/
#school_img {
  padding-bottom: 50px;
}
@media only screen and (min-width: 300px) and (max-width: 746px) {
  #school_img {
    margin-top: 100px;
    padding-bottom: 50px;
  }
}

/**************Key Features************/

.key {
  background-color: #121212;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  width: 280px;
  height: 50px;
  margin-left: 40vw;
}

.Feature {
  background-color: #2aaef7;
  color: #fff;
  font-size: 80px;
  text-align: center;
  width: 480px;
  height: 100px;
  margin-left: 35vw;
  margin-top: 10px;
  transform: rotate(-5deg);
}


@media only screen and (min-width: 300px) and (max-width: 746px) {
  .key {
    background-color: #121212;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    width: 150px;
    height: 50px;
    margin-left: 20vw;
    margin-top: 40vw;
  }
  .Feature {
    background-color: #2aaef7;
    color: #fff;
    font-size: 50px;
    text-align: center;
    width: 250px;
    height: 70px;
    margin-left: 15vw;
    margin-top: 1px;
    transform: rotate(-5deg);
  }
  
}


.main-timeline {
  position: relative;
  margin-bottom: 20px;
}

.main-timeline:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #c6c6c6;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main-timeline .timeline {
  margin-bottom: 40px;
  position: relative;
}

.main-timeline .timeline:after {
  content: "";
  display: block;
  clear: both;
}

.main-timeline .icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.main-timeline .icon:before,
.main-timeline .icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.33s ease-out 0s;
}

.main-timeline .icon:before {
  background: #fff;
  border: 2px solid #232323;
  left: -3px;
}

.main-timeline .icon:after {
  border: 2px solid #c6c6c6;
  left: 3px;
}

.main-timeline .timeline:hover .icon:before {
  left: 3px;
}

.main-timeline .timeline:hover .icon:after {
  left: -3px;
}

.main-timeline .date-content {
  width: 50%;
  float: left;
  margin-top: 22px;
  position: relative;
}

.main-timeline .date-content:before {
  content: "";
  width: 36.5%;
  height: 2px;
  background: #c6c6c6;
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
}

.main-timeline .date-outer {
  width: 80px;
  height: 80px;
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1;
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
  content: "";
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.33s ease-out 0s;
}

.main-timeline .date-outer:before {
  background: #fff;
  border: 2px solid #232323;
  left: -6px;
}

.main-timeline .date-outer:after {
  border: 2px solid #c6c6c6;
  left: 6px;
}

.main-timeline .timeline:hover .date-outer:before {
  left: 6px;
}

.main-timeline .timeline:hover .date-outer:after {
  left: -6px;
}

.main-timeline .date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0;
}

.main-timeline .month {
  font-size: 18px;
  font-weight: 700;
}

.main-timeline .year {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #232323;
  line-height: 36px;
}

.main-timeline .timeline-content {
  width: 50%;
  padding: 20px 0 20px 50px;
  float: right;
}

.main-timeline .title {
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 50px 15px 50px;
}

.main-timeline .description {
  margin-bottom: 0;
  text-align:justify;
  margin:0 50px 0 50px;
}

.main-timeline .timeline:nth-child(2n) .date-content {
  float: right;
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
  left: 10px;
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
  padding: 20px 50px 20px 0;
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .main-timeline .date-content {
    margin-top: 35px;
  }
  .main-timeline .date-content:before {
    width: 22.5%;
  }
  .main-timeline .timeline-content {
    padding: 10px 0 10px 30px;
  }
  .main-timeline .title {
    font-size: 17px;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 10px 30px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-timeline:before {
    margin: 0;
    left: 7px;
  }
  .main-timeline .timeline {
    margin-bottom: 20px;
  }
  .main-timeline .timeline:last-child {
    margin-bottom: 0;
  }
  .main-timeline .icon {
    margin: auto 0;
  }
  .main-timeline .date-content {
    width: 95%;
    float: right;
    margin-top: 0;
  }
  .main-timeline .date-content:before {
    display: none;
  }
  .main-timeline .date-outer {
    width: 80px;
    height: 80px;
  }
  .main-timeline .date-outer:before,
  .main-timeline .date-outer:after {
    width: 80px;
    height: 80px;
  }
  .main-timeline .date {
    top: 30%;
  }
  .main-timeline .year {
    font-size: 24px;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    width: 95%;
    text-align: center;
    padding: 10px 0;
  }
  .main-timeline .title {
    margin-bottom: 10px;
  }
}

/****************About*****************/

.about-p {
  font-size: 20px;
  letter-spacing: 5px;
  line-height: 1;
  
}

.bolt {
  color: #e44002;
}
#about_h3{
  justify-content: center;
  text-align: center;
  font-weight: 900;
  font-size: 40px;
}

.about_container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  
  margin-bottom: -80px;
}

.about_card {
  width: 300px;
  height: 450px;
  margin: 25px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  transition: 0.3s;
  color: #000;
}

.about_card-header {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.about_card-img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin-left: 25px;
}

.about_card-body {
  height: 170;
  padding: 15px;
  text-align: center;
}

.about_card-local {
  font-size: 16px;
  margin: 10px 0;
}

.about_card-title {
  font-size: 24px;
  margin: 10px 0;
}

.about_card-body p {
  font-size: 12px;
  width: 250px;

  color: #000;
}

.about_card-1 .about_card-local {
  color: #2740d1;
}

.about_card-2 .about_card-local {
  color: #d12761;
}

.about_card-3 .about_card-local {
  color: #00c702;
}

.about_card:hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

#goal,.about_card-text,.about_card-text{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: justify;
}


 .Our {
  background-color: #121212;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  width: 280px;
  height: 50px;
  margin-left: 40vw;
  margin-top: 10vw;
}

.About_Methodology {
  background-color: #2aaef7;
  color: #fff;
  font-size: 80px;
  text-align: center;
  width: 480px;
  height: 120px;
  margin-left: 35vw;
  margin-top: 10px;
  transform: rotate(-5deg);
}


@media only screen and (min-width: 300px) and (max-width: 746px) {
  .Our {
    background-color: #121212;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    width: 150px;
    height: 50px;
    margin-left: 20vw;
    margin-top: 120px;
  }
  .About_Methodology{
    background-color: #2aaef7;
    color: #fff;
    font-size: 45px;
    text-align: center;
    width: 310px;
    height: 70px;
    margin-left: 10vw;
    margin-top: 1px;
    transform: rotate(-5deg);
  }
  .about_container {
    justify-content: center;
  }

  .about_card {
    width: calc(100% - 50px);
    max-width: 300px;
  }

  .about_card-img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }

  .about_card-body {
    height: auto;
  }

  .about_card-body p {
    width: 100%;
  }
  #about_h3{
    justify-content: center;
    text-align: center;
    font-weight: 900;
    font-size: 30px;
  }
  .about_card-title {
    font-size: 20px; 
    margin: 5px 0; 
  }
}

.about_main-timeline {
  position: relative;
}

.about_main-timeline:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #c6c6c6;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.about_main-timeline .about_timeline {
  margin-bottom: 40px;
  position: relative;
}

.about_main-timeline .about_timeline:after {
  content: "";
  display: block;
  clear: both;
}

.about_main-timeline .about_icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.about_main-timeline .about_icon:before,
.about_main-timeline .about_icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.33s ease-out 0s;
}

.about_main-timeline .about_icon:before {
  background: #fff;
  border: 2px solid #232323;
  left: -3px;
}

.about_main-timeline .about_icon:after {
  border: 2px solid #c6c6c6;
  left: 3px;
}

.about_main-timeline .about_timeline:hover .about_icon:before {
  left: 3px;
}

.about_main-timeline .about_timeline:hover .about_icon:after {
  left: -3px;
}

.about_main-timeline .about_date-content {
  width: 50%;
  float: left;
  margin-top: 22px;
  position: relative;
}

.about_main-timeline .about_date-content:before {
  content: "";
  width: 36.5%;
  height: 2px;
  background: #c6c6c6;
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
}

.about_main-timeline .about_date-outer {
  width: 80px;
  height: 80px;
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1;
}

.about_main-timeline .about_date-outer:before,
.about_main-timeline .about_date-outer:after {
  content: "";
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.33s ease-out 0s;
}

.about_main-timeline .about_date-outer:before {
  background: #fff;
  border: 2px solid #232323;
  left: -6px;
}

.about_main-timeline .about_date-outer:after {
  border: 2px solid #c6c6c6;
  left: 6px;
}

.about_main-timeline .about_timeline:hover .about_date-outer:before {
  left: 6px;
}

.about_main-timeline .about_timeline:hover .about_date-outer:after {
  left: -6px;
}

.about_main-timeline .about_date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0;
}

.about_main-timeline .about_month {
  font-size: 18px;
  font-weight: 700;
}

.about_main-timeline .about_year {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #232323;
  line-height: 36px;
}

.about_main-timeline .about_timeline-content {
  width: 50%;
  padding: 20px 0 20px 50px;
  float: right;
}

.about_main-timeline .about_title {
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 15px 0;
  margin-right: 20px;
}

.about_main-timeline .about_description {
  margin-bottom: 0;
  text-align: justify;
}

.about_main-timeline .about_timeline:nth-child(2n) .about_date-content {
  float: right;
}

.about_main-timeline .about_timeline:nth-child(2n) .about_date-content:before {
  left: 10px;
}

.about_main-timeline .about_timeline:nth-child(2n) .about_timeline-content {
  padding: 20px 50px 20px 0;
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .about_main-timeline .about_date-content {
    margin-top: 35px;
  }
  .about_main-timeline .about_date-content:before {
    width: 22.5%;
  }
  .about_main-timeline .about_timeline-content {
    padding: 10px 0 10px 30px;
  }
  .about_main-timeline .about_title {
    font-size: 17px;
  }
  .about_main-timeline .timeline:nth-child(2n) .about_timeline-content {
    padding: 10px 30px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .about_main-timeline:before {
    margin: 0;
    left: 7px;
  }
  .about_main-timeline .about_timeline {
    margin-bottom: 20px;
  }
  .about_main-timeline .about_timeline:last-child {
    margin-bottom: 0;
  }
  .about_main-timeline .about_icon {
    margin: auto 0;
  }
  .about_main-timeline .about_date-content {
    width: 95%;
    float: right;
    margin-top: 0;
  }
  .about_main-timeline .about_date-content:before {
    display: none;
  }
  .about_main-timeline .about_date-outer {
    width: 80px;
    height: 80px;
  }
  .about_main-timeline .about_date-outer:before,
  .about_main-timeline .about_date-outer:after {
    width: 80px;
    height: 80px;
  }
  .about_main-timeline .about_date {
    top: 30%;
  }
  .about_main-timeline .about_year {
    font-size: 24px;
  }
  .about_main-timeline .about_timeline-content,
  .about_main-timeline .about_timeline:nth-child(2n) .about_timeline-content {
    width: 95%;
    text-align: center;
    padding: 10px 0;
  }
  .about_main-timeline .about_title {
    margin-bottom: 10px;
  }
}

/**************Footer******************************/
footer {
  background: #000;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0; 
}

footer::before {
  content: "";
  left: 0;
  top: 100px;
  height: 1px;
  width: 100%;
  background: #afafb6;
}

footer .content {
  max-width: 1250px;
  margin: auto;
  padding: 30px 0;
}

footer .content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.content .top .logo-details {
  color: #fff;
  font-size: 30px;
}

.content .top .media-icons {
  display: flex;
}

.content .top .media-icons a {
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}

.top .media-icons a:nth-child(1) {
  background: #1a741c;
}

.top .media-icons a:nth-child(1):hover {
  color: #4267b2;
  background: #fff;
}

.top .media-icons a:nth-child(2) {
  background: #1da1f2;
}

.top .media-icons a:nth-child(2):hover {
  color: #1da1f2;
  background: #fff;
}

.top .media-icons a:nth-child(3) {
  background: #e1306c;
}

.top .media-icons a:nth-child(3):hover {
  color: #e1306c;
  background: #fff;
}

footer .content .link-boxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

footer .content .link-boxes .box {
  width: calc(100% / 5 - 10px);
}

.content .link-boxes .box .link_name {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}

.link-boxes .box .link_name::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 35px;
  background: #fff;
}

.content .link-boxes .box li {
  margin: 6px 0;
  list-style: none;
}

.content .link-boxes .box li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.4s ease;
}

.content .link-boxes .box li a:hover {
  opacity: 1;
  text-decoration: underline;
}

.content .link-boxes .input-box {
  margin-right: 0;
}

.link-boxes .input-box input {
  height: 40px;
  width: 100%;
  outline: none;
  border: 2px solid #afafb6;
  background: #140b5c;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #fff;
  margin-top: 5px;
}

.link-boxes .input-box input::placeholder {
  color: #afafb6;
  font-size: 16px;
}

.link-boxes .input-box button {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  background: #fff;
  color: #140b5c;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}

.input-box button:hover {
  opacity: 1;
}

footer .bottom-details {
  width: 100%;
  background: #393838;
}

footer .bottom-details .bottom_text {
  max-width: 1250px;
  margin: auto;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}

.bottom-details .bottom_text a:hover {
  opacity: 1;
  text-decoration: underline;
}

.bottom-details .bottom_text a {
  margin-right: 10px;
}
@media only screen and (min-width: 300px) and (max-width: 746px) {
  footer::before {
    top: 80px;
  }

  .content .top .logo-details {
    font-size: 24px;
  }

  .content .top .media-icons a {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  .content .top .media-icons a:nth-child(1),
  .content .top .media-icons a:nth-child(2),
  .content .top .media-icons a:nth-child(3) {
    margin: 0 4px;
  }

  .content .link-boxes {
    flex-direction: column;
  }

  .content .link-boxes .box {
    width: 100%;
    margin: 10px 0;
  }

  .content .link-boxes .box:last-child {
    margin-bottom: 0;
  }

  .link-boxes .box .link_name {
    font-size: 16px;
  }

  .link-boxes .box li a {
    font-size: 12px;
  }

  .bottom-details .bottom_text {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .bottom-details .bottom_text a {
    margin-bottom: 5px;
  }
}


@media (max-width: 900px) {
  footer .content .link-boxes {
    flex-wrap: wrap;
  }

  footer .content .link-boxes .input-box {
    width: 40%;
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  

  .content .top .logo-details {
    font-size: 26px;
  }

  .content .top .media-icons a {
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }

  footer .content .link-boxes .box {
    width: calc(50% - 10px);
  }

  footer .content .link-boxes .input-box {
    width: 60%;
  }

  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a {
    font-size: 12px;
  }
  
  
}

@media (max-width: 520px) {
  
  footer::before {
    top: 145px;
  }

  footer .content .top {
    flex-direction: column;
  }

  .content .top .media-icons {
    margin-top: 16px;
  }

  footer .content .link-boxes .box {
    width: calc(100% - 10px);
    
  }

  footer .content .link-boxes .input-box {
    width: 90%;
  }

  .footer_logo {
    width: 200px;
  }

  footer p {
    color: #fff;
    margin-left: 10px;
  }
 
}

.footer_logo {
  width: 200px;
}

footer p {
  color: #fff;
}

/**************************************************/

/****************Button********************************/
.whatsapp_Btn {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s;
}

.whatsapp_svgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  letter-spacing: 0.8px;
  border-radius: 10px;
  transition: all 0.3s;
  border: 1px solid rgba(156, 156, 156, 0.466);
}

.whatsapp_BG {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #075e54;
  z-index: -1;
  border-radius: 10px;
  pointer-events: none;
  transition: all 0.3s;
}

.whatsapp_Btn:hover .whatsapp_BG {
  transform: rotate(35deg);
  transform-origin: bottom;
}

.whatsapp_Btn:hover .whatsapp_svgContainer {
  background-color: rgba(156, 156, 156, 0.466);
}

@media screen and (min-width: 300px) and (min-height: 746px) {
  .whatsapp_Btn {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s;
  }
}

@media screen and (max-width: 746px) {
  .whatsapp_Btn {
    width: 50px;
    height: 45px;
    bottom: 10px;
    right: 10px;
    border-radius: 7px;
  }

  .whatsapp_Btn:hover .whatsapp_BG {
    transform: rotate(35deg);
    transform-origin: bottom;
  }
  .whatsapp_BG.rotate {
    transform: rotate(35deg);
    transform-origin: bottom;
  }
}

.whatsapp_Btn.scrolled {
  bottom: 10px;
}

.whatsapp_BG.rotate {
  transform: rotate(35deg);
  transform-origin: bottom;
}

/***************404 page not found********************/
.construction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.text-container,
.image-container {
  display: inline-block;
}

.text-container {
  text-align: center;
  margin-right: 50px;
}

.image-container {
  position: relative;
}

.construction-image {
  width: 500px;
  height: 550px;
  animation: moveUpDown 2s linear infinite alternate;
}
.constimg{
  text-decoration: none;
  color:#fff;
}
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}

@media screen and (max-width: 767px) {
  .construction-image {
    width: 100%; 
    height: auto; 
  }

  .text-container {
    margin-right: 0; 
    margin-bottom: 20px; 
  }
}


/*****************************************************/
/***********************Gallery**********************/
.OurProjects_H3 {
  justify-content:center;
  text-align: center;
  font-size: 30px;
  color: #fff;
}

.OurProjects_P {
  text-align: justify;
  font-size: 20px;
  color: #fff;
  margin: 0 50px;
}

.OurProjectGallery {
  position: relative;
  height: auto;
}
.OurProjectGallery {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.swiper {
  width: 400px;
  height: 20%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 10px 50px;
  border: 10px #000 solid ;
  background-color: #fff;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: auto;
  background-color: #000;
}

.swiper-slide img {
  display: block;
  width: 400px;
}
@media screen and (min-width: 300px) and (min-height: 746px){
  .OurProjects_H3{
    font-size: 20px;
    font-weight: 700;
    margin: 20px 20px;
  }
}
