body {
  margin:0;
  padding:0;
  box-sizing: border-box;
}

code {
  font-family: Georgia, 'Times New Roman', Times, serif,
    monospace;
}
